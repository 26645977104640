import { Entity } from '@jobilla/entity';

export class CompanyBankingDetails extends Entity {
  public id: number = null;
  public routingNumber: string = '';
  public institutionNumber: string = '';
  public accountNumber: string = '';
  public billingEmail: string = '';
  public accountTitle: string = '';
}
