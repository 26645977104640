import { Injectable } from '@angular/core';
import { entities, entity } from '@core/utils/rxjs';
import { ApiService } from '@services/api/api.service';
import { Observable, shareReplay } from 'rxjs';
import { CompanyBankingDetails } from '@entities/companies/company-banking-details.entity';
import { WorkerBankingDetails } from '@entities/workers/worker-banking-details.entity';

@Injectable({
  providedIn: 'root',
})
export class BankingDetailsService {
  public constructor(private api: ApiService) {}

  public retrieveCompanyBankingDetailsById(
    companyId: number,
    bankingDetailsId: number
  ): Observable<CompanyBankingDetails> {
    return this.api
      .get<CompanyBankingDetails>('companies/' + companyId + '/banking-details/' + bankingDetailsId)
      .pipe(entity<CompanyBankingDetails>(CompanyBankingDetails), shareReplay());
  }

  public createCompanyBankingDetails(
    companyId: number,
    bankingDetails: CompanyBankingDetails
  ): Observable<CompanyBankingDetails> {
    return this.api
      .post<CompanyBankingDetails>('companies/' + companyId + '/banking-details/', bankingDetails)
      .pipe(entity<CompanyBankingDetails>(CompanyBankingDetails), shareReplay());
  }

  public updateCompanyBankingDetails(
    companyId: number,
    bankingDetails: CompanyBankingDetails
  ): Observable<CompanyBankingDetails> {
    return this.api
      .put<CompanyBankingDetails>('companies/' + companyId + '/banking-details/' + bankingDetails.id, bankingDetails)
      .pipe(entity<CompanyBankingDetails>(CompanyBankingDetails), shareReplay());
  }

  public retrieveWorkerBankingDetails(workerId: number): Observable<WorkerBankingDetails[]> {
    return this.api
      .get<WorkerBankingDetails>('workers/' + workerId + '/banking-details')
      .pipe(entities<WorkerBankingDetails>(WorkerBankingDetails), shareReplay());
  }

  public createWorkerBankingDetails(
    workerId: number,
    bankingDetails: WorkerBankingDetails
  ): Observable<WorkerBankingDetails> {
    return this.api
      .post<WorkerBankingDetails>('workers/' + workerId + '/banking-details/', bankingDetails)
      .pipe(entity<WorkerBankingDetails>(WorkerBankingDetails), shareReplay());
  }

  public createWorkerDebitCardDetails(workerId: number, cardToken: string): Observable<WorkerBankingDetails> {
    return this.api
      .post<WorkerBankingDetails>('workers/' + workerId + '/banking-details/card', { cardToken: cardToken })
      .pipe(entity<WorkerBankingDetails>(WorkerBankingDetails), shareReplay());
  }

  public updateWorkerBankingDetails(
    workerId: number,
    bankingDetails: WorkerBankingDetails
  ): Observable<WorkerBankingDetails> {
    return this.api
      .put<WorkerBankingDetails>('workers/' + workerId + '/banking-details/' + bankingDetails.id, bankingDetails)
      .pipe(entity<WorkerBankingDetails>(WorkerBankingDetails), shareReplay());
  }

  public deleteWorkerBankingDetails(workerId: number, bankingDetailsId: number): Observable<void> {
    return this.api.delete<void>('workers/' + workerId + '/banking-details/' + bankingDetailsId).pipe(shareReplay());
  }
}
